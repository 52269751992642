/**
 * File generated by js-routes 2.2.4
 * Based on Rails 6.1.7.3 routes of AmazingDiscoveries::Application
 */
const __jsr = (() => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const isBrowser = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define() {
                // Defined using const __jsr = 
            },
            isSupported() {
                return true;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            let query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (key === "params") {
                    if (this.is_object(value)) {
                        query_parameters = {
                            ...query_parameters,
                            ...value,
                        };
                    }
                    else {
                        throw new Error("params value should always be an object");
                    }
                }
                else if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBrowser && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})();
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /admin/users/:user_id/active(.:format)
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_activate_user_path = __jsr.r({"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"active"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/api/ebooks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_api_ebook_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"ebooks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/api/episodes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_api_episodes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/api/featured_videos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_api_featured_video_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"featured_videos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/api/featured_videos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_api_featured_videos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"featured_videos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/api/news(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_api_news_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"news"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/api/pictures/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_api_picture_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"pictures"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/api/recipient(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_api_recipient_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"recipient"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/api/series(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_api_series_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"series"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/articles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_article_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"articles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/article_categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_article_categories_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"article_categories"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/article_categories/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_article_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"article_categories"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/article_subjects/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_article_subject_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"article_subjects"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/article_subjects(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_article_subjects_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"article_subjects"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/article_topics/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_article_topic_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"article_topics"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/article_topics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_article_topics_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"article_topics"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/articles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_articles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"articles"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/audios/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_audio_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"audios"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/audios(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_audios_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"audios"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/authors/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_author_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"authors"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/authors(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_authors_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"authors"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/banners/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_banner_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"banners"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/banners(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_banners_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"banners"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_categories_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"categories"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/categories/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"categories"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/categories/:category_id/thumbnails/:id(.:format)
 * @param {any} category_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_category_thumbnail_path = __jsr.r({"category_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"categories"],[2,[7,"/"],[2,[3,"category_id"],[2,[7,"/"],[2,[6,"thumbnails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/categories/:category_id/thumbnails(.:format)
 * @param {any} category_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_category_thumbnails_path = __jsr.r({"category_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"categories"],[2,[7,"/"],[2,[3,"category_id"],[2,[7,"/"],[2,[6,"thumbnails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/clone_message_center/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_clone_message_center_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"clone_message_center"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ebooks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_ebook_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ebooks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ebook_topics/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_ebook_topic_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ebook_topics"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ebook_topics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_ebook_topics_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ebook_topics"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/ebooks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_ebooks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ebooks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/email_notifications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_email_notification_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"email_notifications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/email_notifications/:email_notification_id/remove_attachment(.:format)
 * @param {any} email_notification_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_email_notification_remove_attachment_path = __jsr.r({"email_notification_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"email_notifications"],[2,[7,"/"],[2,[3,"email_notification_id"],[2,[7,"/"],[2,[6,"remove_attachment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/email_notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_email_notifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"email_notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/episodes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_episode_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/episode_comments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_episode_comment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"episode_comments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/episodes/:episode_id/covers/:id(.:format)
 * @param {any} episode_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_episode_cover_path = __jsr.r({"episode_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"covers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/episodes/:episode_id/covers(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_episode_covers_path = __jsr.r({"episode_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"covers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/episodes/:episode_id/remove_audio(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_episode_remove_audio_path = __jsr.r({"episode_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"remove_audio"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/episodes/:episode_id/thumbnails/:id(.:format)
 * @param {any} episode_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_episode_thumbnail_path = __jsr.r({"episode_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"thumbnails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/episodes/:episode_id/thumbnails(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_episode_thumbnails_path = __jsr.r({"episode_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"thumbnails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/episodes/:episode_id/videos(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_episode_videos_path = __jsr.r({"episode_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"videos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/episodes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_episodes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"episodes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/message_center/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_message_center_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"message_center"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/message_center(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_message_center_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"message_center"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/news/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_news_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/news/:news_id/covers/:id(.:format)
 * @param {any} news_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_news_cover_path = __jsr.r({"news_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[3,"news_id"],[2,[7,"/"],[2,[6,"covers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/news/:news_id/covers(.:format)
 * @param {any} news_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_news_covers_path = __jsr.r({"news_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[3,"news_id"],[2,[7,"/"],[2,[6,"covers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/news_disclaimer(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_news_disclaimer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"news_disclaimer"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/news_import/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_news_import_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"news_import"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/news_import(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_news_import_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"news_import"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/news(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_news_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"news"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/news/:news_id/thumbnails/:id(.:format)
 * @param {any} news_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_news_thumbnail_path = __jsr.r({"news_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[3,"news_id"],[2,[7,"/"],[2,[6,"thumbnails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/news/:news_id/thumbnails(.:format)
 * @param {any} news_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_news_thumbnails_path = __jsr.r({"news_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[3,"news_id"],[2,[7,"/"],[2,[6,"thumbnails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/news_topics/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_news_topic_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"news_topics"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/news_topics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_news_topics_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"news_topics"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/pages/:page_id/thumbnails/:id(.:format)
 * @param {any} page_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_page_thumbnail_path = __jsr.r({"page_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"thumbnails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pages/:page_id/thumbnails(.:format)
 * @param {any} page_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_page_thumbnails_path = __jsr.r({"page_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"thumbnails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/passcodes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_passcode_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"passcodes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/passcodes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_passcodes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"passcodes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/pictures/modifier/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_pictures_modifier_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pictures"],[2,[7,"/"],[2,[6,"modifier"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pre_set_notifications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_pre_set_notification_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pre_set_notifications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pre_set_notifications/:pre_set_notification_id/image(.:format)
 * @param {any} pre_set_notification_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_pre_set_notification_image_path = __jsr.r({"pre_set_notification_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pre_set_notifications"],[2,[7,"/"],[2,[3,"pre_set_notification_id"],[2,[7,"/"],[2,[6,"image"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pre_set_notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_pre_set_notifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pre_set_notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/preview_email_notifications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_preview_email_notification_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"preview_email_notifications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/preview_message_centers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_preview_message_center_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"preview_message_centers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/preview_welcome_email_settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_preview_welcome_email_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"preview_welcome_email_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/prophetic_news/imports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_prophetic_news_import_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"prophetic_news"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/prophetic_news/imports/:import_id/state(.:format)
 * @param {any} import_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_prophetic_news_import_state_path = __jsr.r({"import_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"prophetic_news"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[3,"import_id"],[2,[7,"/"],[2,[6,"state"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/prophetic_news/imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_prophetic_news_imports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"prophetic_news"],[2,[7,"/"],[2,[6,"imports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/episodes/:episode_id/publish(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_publish_episode_path = __jsr.r({"episode_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"publish"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/series/:series_id/publish(.:format)
 * @param {any} series_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_publish_series_path = __jsr.r({"series_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"series"],[2,[7,"/"],[2,[3,"series_id"],[2,[7,"/"],[2,[6,"publish"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reported_episode_comments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_reported_episode_comment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reported_episode_comments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reported_episode_comments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_reported_episode_comments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reported_episode_comments"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/restricted_from_commenting_users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_restricted_from_commenting_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"restricted_from_commenting_users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:user_id/confirm(.:format)
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_send_confirmation_user_path = __jsr.r({"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"confirm"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/notifications/:notification_id/perform(.:format)
 * @param {any} notification_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_send_email_path = __jsr.r({"notification_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[3,"notification_id"],[2,[7,"/"],[2,[6,"perform"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:user_id/reset_password(.:format)
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_send_reset_password_user_path = __jsr.r({"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"reset_password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/series/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_series_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"series"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/series/:series_id/covers/:id(.:format)
 * @param {any} series_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_series_cover_path = __jsr.r({"series_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"series"],[2,[7,"/"],[2,[3,"series_id"],[2,[7,"/"],[2,[6,"covers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/series/:series_id/covers(.:format)
 * @param {any} series_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_series_covers_path = __jsr.r({"series_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"series"],[2,[7,"/"],[2,[3,"series_id"],[2,[7,"/"],[2,[6,"covers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/series/:series_id/episodes/:id(.:format)
 * @param {any} series_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_series_episode_path = __jsr.r({"series_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"series"],[2,[7,"/"],[2,[3,"series_id"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/series/:series_id/episodes(.:format)
 * @param {any} series_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_series_episodes_path = __jsr.r({"series_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"series"],[2,[7,"/"],[2,[3,"series_id"],[2,[7,"/"],[2,[6,"episodes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/series(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_series_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"series"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/series/:series_id/thumbnails/:id(.:format)
 * @param {any} series_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_series_thumbnail_path = __jsr.r({"series_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"series"],[2,[7,"/"],[2,[3,"series_id"],[2,[7,"/"],[2,[6,"thumbnails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/series/:series_id/thumbnails(.:format)
 * @param {any} series_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_series_thumbnails_path = __jsr.r({"series_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"series"],[2,[7,"/"],[2,[3,"series_id"],[2,[7,"/"],[2,[6,"thumbnails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/speakers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_speaker_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"speakers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/speakers/:speaker_id/thumbnails/:id(.:format)
 * @param {any} speaker_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_speaker_thumbnail_path = __jsr.r({"speaker_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"speakers"],[2,[7,"/"],[2,[3,"speaker_id"],[2,[7,"/"],[2,[6,"thumbnails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/speakers/:speaker_id/thumbnails(.:format)
 * @param {any} speaker_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_speaker_thumbnails_path = __jsr.r({"speaker_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"speakers"],[2,[7,"/"],[2,[3,"speaker_id"],[2,[7,"/"],[2,[6,"thumbnails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/speakers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_speakers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"speakers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/standalone_episodes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_standalone_episode_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"standalone_episodes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/standalone_episodes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_standalone_episodes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"standalone_episodes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/videos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_video_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"videos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/videos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_videos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"videos"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/watch_topics/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_watch_topic_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"watch_topics"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/watch_topics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_watch_topics_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"watch_topics"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/welcome_email_settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_welcome_email_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"welcome_email_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/welcome_email_settings/remove_attachment/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_welcome_email_settings_remove_attachment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"welcome_email_settings"],[2,[7,"/"],[2,[6,"remove_attachment"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * //admin/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_user_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //admin/signup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_signup_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"signup"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //admin/login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_login_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //admin/logout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_logout_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"logout"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //admin/passwords(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_new_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"passwords"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //admin/passwords/:id/edit/:reset_password_token(.:format)
 * @param {any} id
 * @param {any} reset_password_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_edit_password_path = __jsr.r({"id":{"r":true},"reset_password_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[2,[7,"/"],[2,[3,"reset_password_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/passwords(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_reset_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"passwords"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //admin/passwords(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_update_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"passwords"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //admin/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //admin/users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_new_admin_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_edit_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * //admin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * //admin/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //admin/dashboard/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_dashboard_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/help(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_help_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"help"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //admin/dashboard/update_check(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_update_check_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"update_check"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/leave(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_leave_admin_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"leave"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //admin/contents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_contents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"contents"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //admin/nodes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_nodes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"nodes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //admin/nodes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_new_admin_node_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"nodes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/nodes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_edit_admin_node_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"nodes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/nodes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_node_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"nodes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/pages/:page_id/elements(.:format)
 * @param {any} page_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_page_elements_path = __jsr.r({"page_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"elements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/pages/:page_id/elements/new(.:format)
 * @param {any} page_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_new_admin_page_element_path = __jsr.r({"page_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"elements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/pages/:page_id/elements/:id/edit(.:format)
 * @param {any} page_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_edit_admin_page_element_path = __jsr.r({"page_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"elements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/pages/:page_id/elements/:id(.:format)
 * @param {any} page_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_page_element_path = __jsr.r({"page_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"elements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/pages/order(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_order_admin_pages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/pages/flush(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_flush_admin_pages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"flush"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/pages/copy_language_tree(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_copy_language_tree_admin_pages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"copy_language_tree"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/pages/create_language(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_create_language_admin_pages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"create_language"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/pages/link(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_link_admin_pages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"link"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/pages/tree(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_tree_admin_pages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"tree"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/pages/:id/unlock(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_unlock_admin_page_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/pages/:id/publish(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_publish_admin_page_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"publish"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/pages/:id/fold(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_fold_admin_page_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"fold"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/pages/:id/configure(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_configure_admin_page_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"configure"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/pages/:id/preview(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_preview_admin_page_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/pages/:id/info(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_info_admin_page_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/pages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_pages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pages"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //admin/pages/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_new_admin_page_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/pages/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_edit_admin_page_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/pages/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_page_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/elements/:element_id/contents(.:format)
 * @param {any} element_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_element_contents_path = __jsr.r({"element_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"elements"],[2,[7,"/"],[2,[3,"element_id"],[2,[7,"/"],[2,[6,"contents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/elements/:element_id/contents/new(.:format)
 * @param {any} element_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_new_admin_element_content_path = __jsr.r({"element_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"elements"],[2,[7,"/"],[2,[3,"element_id"],[2,[7,"/"],[2,[6,"contents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/elements/:element_id/contents/:id/edit(.:format)
 * @param {any} element_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_edit_admin_element_content_path = __jsr.r({"element_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"elements"],[2,[7,"/"],[2,[3,"element_id"],[2,[7,"/"],[2,[6,"contents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/elements/:element_id/contents/:id(.:format)
 * @param {any} element_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_element_content_path = __jsr.r({"element_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"elements"],[2,[7,"/"],[2,[3,"element_id"],[2,[7,"/"],[2,[6,"contents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/elements/order(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_order_admin_elements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"elements"],[2,[7,"/"],[2,[6,"order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/elements/:id/publish(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_publish_admin_element_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"elements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"publish"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/elements/:id/fold(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_fold_admin_element_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"elements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"fold"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/elements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_elements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"elements"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //admin/elements/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_new_admin_element_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"elements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/elements/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_edit_admin_element_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"elements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/elements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_element_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"elements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/layoutpages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_layoutpages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"layoutpages"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //admin/layoutpages/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_edit_admin_layoutpage_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"layoutpages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/pictures/update_multiple(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_update_multiple_admin_pictures_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pictures"],[2,[7,"/"],[2,[6,"update_multiple"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/pictures/delete_multiple(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_delete_multiple_admin_pictures_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pictures"],[2,[7,"/"],[2,[6,"delete_multiple"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/pictures/edit_multiple(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_edit_multiple_admin_pictures_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pictures"],[2,[7,"/"],[2,[6,"edit_multiple"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/pictures/:id/url(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_url_admin_picture_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pictures"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"url"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/pictures/:id/assign(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_assign_admin_picture_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pictures"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"assign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/pictures/:id/remove(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_remove_admin_picture_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pictures"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/pictures(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_pictures_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pictures"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //admin/pictures/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_edit_admin_picture_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pictures"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/pictures/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_picture_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pictures"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/attachments/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_download_admin_attachment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"attachments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/attachments/:id/assign(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_assign_admin_attachment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"attachments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"assign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/attachments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_attachments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"attachments"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //admin/attachments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_edit_admin_attachment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"attachments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/attachments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_attachment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"attachments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/essence_audios/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_edit_admin_essence_audio_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"essence_audios"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/essence_audios/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_essence_audio_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"essence_audios"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/essence_pictures/:id/crop(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_crop_admin_essence_picture_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"essence_pictures"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"crop"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/essence_pictures/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_edit_admin_essence_picture_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"essence_pictures"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/essence_pictures/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_essence_picture_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"essence_pictures"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/essence_files/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_edit_admin_essence_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"essence_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/essence_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_essence_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"essence_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/essence_videos/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_edit_admin_essence_video_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"essence_videos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/essence_videos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_essence_video_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"essence_videos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/ingredients/:id/crop(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_crop_admin_ingredient_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ingredients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"crop"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/ingredients/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_edit_admin_ingredient_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ingredients"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/ingredients/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_ingredient_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ingredients"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/legacy_page_urls(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_legacy_page_urls_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"legacy_page_urls"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //admin/legacy_page_urls/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_new_admin_legacy_page_url_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"legacy_page_urls"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/legacy_page_urls/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_edit_admin_legacy_page_url_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"legacy_page_urls"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/legacy_page_urls/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_legacy_page_url_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"legacy_page_urls"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/languages/switch(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_switch_admin_languages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"languages"],[2,[7,"/"],[2,[6,"switch"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/languages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_languages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"languages"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //admin/languages/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_new_admin_language_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"languages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/languages/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_edit_admin_language_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"languages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/languages/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_language_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"languages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/clipboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_clipboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"clipboard"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //admin/clipboard/clear(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_clear_admin_clipboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"clipboard"],[2,[7,"/"],[2,[6,"clear"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/clipboard/remove(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_remove_admin_clipboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"clipboard"],[2,[7,"/"],[2,[6,"remove"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/clipboard/insert(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_insert_admin_clipboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"clipboard"],[2,[7,"/"],[2,[6,"insert"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/tags/autocomplete(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_autocomplete_admin_tags_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tags"],[2,[7,"/"],[2,[6,"autocomplete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/tags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_tags_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tags"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //admin/tags/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_new_admin_tag_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tags"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/tags/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_edit_admin_tag_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tags"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/tags/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_tag_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tags"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/sites(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_sites_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sites"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //admin/sites/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_new_admin_site_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sites"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/sites/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_edit_admin_site_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sites"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/sites/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_site_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sites"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //admin/styleguide(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_admin_styleguide_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"styleguide"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //attachment/:id/download(/:name)(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_download_attachment_path = __jsr.r({"id":{"r":true},"name":{},"format":{}}, [2,[7,"/"],[2,[6,"attachment"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[2,[1,[2,[7,"/"],[3,"name"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * //attachment/:id/show(/:name)(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_show_attachment_path = __jsr.r({"id":{"r":true},"name":{},"format":{}}, [2,[7,"/"],[2,[6,"attachment"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"show"],[2,[1,[2,[7,"/"],[3,"name"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * //messages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_messages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"messages"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * //messages/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_new_message_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //elements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_element_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"elements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //api/contents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_api_contents_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contents"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //api/contents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_api_content_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //api/elements/:element_id/contents(.:format)
 * @param {any} element_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_api_element_contents_path = __jsr.r({"element_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"elements"],[2,[7,"/"],[2,[3,"element_id"],[2,[7,"/"],[2,[6,"contents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //api/elements/:element_id/contents/:name(.:format)
 * @param {any} element_id
 * @param {any} name
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_api_element_content_path = __jsr.r({"element_id":{"r":true},"name":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"elements"],[2,[7,"/"],[2,[3,"element_id"],[2,[7,"/"],[2,[6,"contents"],[2,[7,"/"],[2,[3,"name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * //api/elements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_api_elements_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"elements"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //api/elements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_api_element_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"elements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //api/pages/:page_id/elements(.:format)
 * @param {any} page_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_api_page_elements_path = __jsr.r({"page_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"elements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //api/pages/:page_id/elements/:named(.:format)
 * @param {any} page_id
 * @param {any} named
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_api_page_named_elements_path = __jsr.r({"page_id":{"r":true},"named":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"page_id"],[2,[7,"/"],[2,[6,"elements"],[2,[7,"/"],[2,[3,"named"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * //api/pages/nested(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_nested_api_pages_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"nested"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //api/pages/:id/move(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_move_api_page_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"move"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //api/pages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_api_pages_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"pages"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //api/pages/*urlname(.:format)
 * @param {any} urlname
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_api_page_path = __jsr.r({"urlname":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[5,[3,"urlname"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * //api/admin/pages/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_api_preview_page_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //api/nodes/:id/move(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_move_api_node_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"nodes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"move"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //api/nodes/:id/toggle_folded(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_toggle_folded_api_node_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"nodes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_folded"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * //api/nodes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_api_nodes_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"nodes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * //:locale(.:format)
 * @param {any} locale
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_show_language_root_path = __jsr.r({"locale":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"locale"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /(/:locale)/*urlname(.:format)
 * @param {any} urlname
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const alchemy_show_page_path = __jsr.r({"locale":{},"urlname":{"r":true},"format":{}}, [2,[1,[2,[7,"/"],[3,"locale"]]],[2,[7,"/"],[2,[5,[3,"urlname"]],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /api/admin/jobs/statuses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_admin_jobs_status_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"jobs"],[2,[7,"/"],[2,[6,"statuses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/admin/medias/imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_admin_medias_imports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"medias"],[2,[7,"/"],[2,[6,"imports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/admin/medias/sas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_admin_medias_sas_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"medias"],[2,[7,"/"],[2,[6,"sas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/admin/videos/skus/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_admin_videos_sku_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"videos"],[2,[7,"/"],[2,[6,"skus"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/articles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_article_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"articles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/banners/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_banner_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"banners"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/banners(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_banners_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"banners"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/bookmarks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_bookmark_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"bookmarks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/bookmarks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_bookmarks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"bookmarks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/comments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_comment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"comments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/comments/:comment_id/reports(.:format)
 * @param {any} comment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_comment_reports_path = __jsr.r({"comment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"comments"],[2,[7,"/"],[2,[3,"comment_id"],[2,[7,"/"],[2,[6,"reports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/comments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_comments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"comments"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/users/donate_warning(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_donate_warning_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"donate_warning"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_episode_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/documents/:id(.:format)
 * @param {any} episode_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_episode_document_path = __jsr.r({"episode_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/documents(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_episode_documents_path = __jsr.r({"episode_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/ebooks/:id(.:format)
 * @param {any} episode_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_episode_ebook_path = __jsr.r({"episode_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"ebooks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/ebooks(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_episode_ebooks_path = __jsr.r({"episode_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"ebooks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/guides/:id(.:format)
 * @param {any} episode_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_episode_guide_path = __jsr.r({"episode_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"guides"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/guides(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_episode_guides_path = __jsr.r({"episode_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"guides"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/images/:id(.:format)
 * @param {any} episode_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_episode_image_path = __jsr.r({"episode_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"images"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/images(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_episode_images_path = __jsr.r({"episode_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"images"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/info(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_episode_info_index_path = __jsr.r({"episode_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/like(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_episode_like_path = __jsr.r({"episode_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"like"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/links/:id(.:format)
 * @param {any} episode_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_episode_link_path = __jsr.r({"episode_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"links"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/links(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_episode_links_path = __jsr.r({"episode_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"links"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/transcripts/:id(.:format)
 * @param {any} episode_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_episode_transcript_path = __jsr.r({"episode_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"transcripts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/transcripts(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_episode_transcripts_path = __jsr.r({"episode_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"transcripts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/bookmarks/episodes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_episodes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"bookmarks"],[2,[7,"/"],[2,[6,"episodes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/featured_videos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_featured_video_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"featured_videos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/news/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_news_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/news_bookmarks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_news_bookmark_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"news_bookmarks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/news_bookmarks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_news_bookmarks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"news_bookmarks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/news_content/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_news_content_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"news_content"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/news_bookmarks/news(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_news_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"news_bookmarks"],[2,[7,"/"],[2,[6,"news"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/newsletter_subscriptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_newsletter_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"newsletter_subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/notes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_note_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"notes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/notes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_notes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"notes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/notifications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_notification_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_notifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/passcode_requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_passcode_requests_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"passcode_requests"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/programs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_programs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"programs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/programs_dates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_programs_dates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"programs_dates"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/progress_bars(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_progress_bars_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"progress_bars"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/recent_searches/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_recent_search_path = __jsr.r({"id":{"r":true,"d":null},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"recent_searches"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/recent_searches(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_recent_searches_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"recent_searches"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/report_reasons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_report_reasons_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"report_reasons"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/roku(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_roku_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"roku"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_search_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/series/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_series_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"series"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/bookmarks/tags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_tags_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"bookmarks"],[2,[7,"/"],[2,[6,"tags"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/tutorial(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_tutorial_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"tutorial"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/watch_history/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_watch_history_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"watch_history"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/watch_history(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_watch_history_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"watch_history"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/profiles/profile/areas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const areas_mobile_api_profiles_profiles_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"areas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ebooks/:id/assign(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assign_admin_ebook_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ebooks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"assign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/essence_videos/assign(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assign_admin_essence_videos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"essence_videos"],[2,[7,"/"],[2,[6,"assign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /auth/confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auth_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /auth/login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auth_login_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /auth/logout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auth_logout_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"logout"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /auth/login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auth_new_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /auth/oauth/bigcommerce(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auth_oauth_bigcommerce_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"bigcommerce"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /auth/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auth_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /auth/registration(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auth_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"registration"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/passcodes/autocomplete(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autocomplete_admin_passcodes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"passcodes"],[2,[7,"/"],[2,[6,"autocomplete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/transcripts/autogenerate(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autogenerate_api_episode_transcripts_path = __jsr.r({"episode_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"transcripts"],[2,[7,"/"],[2,[6,"autogenerate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /studytools/:id/bookmarks(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bookmarks_profile_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"studytools"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"bookmarks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /studytools/:id/change_email(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_email_profile_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"studytools"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"change_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /studytools/:id/change_password(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_password_profile_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"studytools"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"change_password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/profiles/profile/cities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cities_mobile_api_profiles_profiles_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"cities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /studytools/cities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cities_profile_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"studytools"],[2,[7,"/"],[2,[6,"cities"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /studytools/:id/dashboard(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_profile_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"studytools"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/videos/delete_multiple(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_multiple_admin_videos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"videos"],[2,[7,"/"],[2,[6,"delete_multiple"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/announcements/:id/dismiss(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dismiss_api_announcement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"announcements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"dismiss"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ebooks/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_admin_ebook_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ebooks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ebook/:id/download(/:name)(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_ebook_path = __jsr.r({"id":{"r":true},"name":{},"format":{}}, [2,[7,"/"],[2,[6,"ebook"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[2,[1,[2,[7,"/"],[3,"name"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * /ebooks/topics/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ebook_topic_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ebooks"],[2,[7,"/"],[2,[6,"topics"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ebooks/topics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ebook_topics_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ebooks"],[2,[7,"/"],[2,[6,"topics"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/api/featured_videos/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_api_featured_video_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"featured_videos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/articles/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_article_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"articles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/article_categories/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_article_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"article_categories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/article_subjects/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_article_subject_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"article_subjects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/article_topics/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_article_topic_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"article_topics"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/authors/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_author_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"authors"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/banners/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_banner_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"banners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/categories/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"categories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ebooks/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_ebook_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ebooks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ebook_topics/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_ebook_topic_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ebook_topics"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/email_notifications/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_email_notification_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"email_notifications"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/message_center/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_message_center_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"message_center"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/news_import/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_news_import_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"news_import"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/news_topics/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_news_topic_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"news_topics"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/pre_set_notifications/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_pre_set_notification_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"pre_set_notifications"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/series/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_series_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"series"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/series/:series_id/episodes/:id/edit(.:format)
 * @param {any} series_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_series_episode_path = __jsr.r({"series_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"series"],[2,[7,"/"],[2,[3,"series_id"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/speakers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_speaker_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"speakers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/standalone_episodes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_standalone_episode_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"standalone_episodes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/videos/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_video_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"videos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/watch_topics/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_watch_topic_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"watch_topics"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/banners/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_banner_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"banners"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/documents/:id/edit(.:format)
 * @param {any} episode_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_episode_document_path = __jsr.r({"episode_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/ebooks/:id/edit(.:format)
 * @param {any} episode_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_episode_ebook_path = __jsr.r({"episode_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"ebooks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/guides/:id/edit(.:format)
 * @param {any} episode_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_episode_guide_path = __jsr.r({"episode_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"guides"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/images/:id/edit(.:format)
 * @param {any} episode_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_episode_image_path = __jsr.r({"episode_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"images"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/links/:id/edit(.:format)
 * @param {any} episode_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_episode_link_path = __jsr.r({"episode_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"links"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/transcripts/:id/edit(.:format)
 * @param {any} episode_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_episode_transcript_path = __jsr.r({"episode_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"transcripts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /auth/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_auth_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/auth/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_mobile_api_auth_password_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/ebooks/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_mobile_api_ebook_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"ebooks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_mobile_api_episode_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/videos/edit_multiple(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_multiple_admin_videos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"videos"],[2,[7,"/"],[2,[6,"edit_multiple"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/series/:series_id/episodes/:id/edit_order(.:format)
 * @param {any} series_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_order_admin_series_episode_path = __jsr.r({"series_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"series"],[2,[7,"/"],[2,[3,"series_id"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/speakers/:id/edit_popularity(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_popularity_admin_speaker_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"speakers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_popularity"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /studytools/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_profile_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"studytools"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /elements/watch_sections/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const elements_watch_section_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"elements"],[2,[7,"/"],[2,[6,"watch_sections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /auth/password/expired(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const expired_auth_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"expired"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /find_cities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const find_cities_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"find_cities"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /find_states(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const find_states_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"find_states"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /healthcheck(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const healthcheck_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"healthcheck"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /job_opportunities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const job_opportunities_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"job_opportunities"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /studytools/:id/message_center(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const message_center_profile_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"studytools"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"message_center"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/all_bookmark_tags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_all_bookmark_tags_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"all_bookmark_tags"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/all_bookmarks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_all_bookmarks_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"all_bookmarks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/android_update_app_version(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_android_update_app_version_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"android_update_app_version"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/articles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_article_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"articles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/article_categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_article_categories_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"article_categories"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/article_categories/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_article_category_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"article_categories"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/article_subjects/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_article_subject_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"article_subjects"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/article_subjects/:article_subject_id/articles(.:format)
 * @param {any} article_subject_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_article_subject_articles_path = __jsr.r({"article_subject_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"article_subjects"],[2,[7,"/"],[2,[3,"article_subject_id"],[2,[7,"/"],[2,[6,"articles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/article_subjects(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_article_subjects_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"article_subjects"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/article_topics/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_article_topic_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"article_topics"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/article_topics/:article_topic_id/articles(.:format)
 * @param {any} article_topic_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_article_topic_articles_path = __jsr.r({"article_topic_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"article_topics"],[2,[7,"/"],[2,[3,"article_topic_id"],[2,[7,"/"],[2,[6,"articles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/article_topics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_article_topics_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"article_topics"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/articles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_articles_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"articles"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/articles/recent_searches(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_articles_recent_searches_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"articles"],[2,[7,"/"],[2,[6,"recent_searches"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/auth/confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_auth_confirmation_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/auth/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_auth_password_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/auth/registration(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_auth_registration_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"registration"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/auth/sessions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_auth_sessions_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"sessions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/banner_articles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_banner_articles_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"banner_articles"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/bookmark_tags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_bookmark_tags_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"bookmark_tags"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/bookmarks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_bookmarks_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"bookmarks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/bookmarks/episodes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_bookmarks_episodes_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"bookmarks"],[2,[7,"/"],[2,[6,"episodes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_categories_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"categories"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/categories/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_category_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"categories"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/categories/:category_id/cards(.:format)
 * @param {any} category_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_category_cards_path = __jsr.r({"category_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"categories"],[2,[7,"/"],[2,[3,"category_id"],[2,[7,"/"],[2,[6,"cards"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/categories/:category_id/topics(.:format)
 * @param {any} category_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_category_topics_path = __jsr.r({"category_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"categories"],[2,[7,"/"],[2,[3,"category_id"],[2,[7,"/"],[2,[6,"topics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/articles/:id/content(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_content_path = __jsr.r({"id":{"r":true},"format":{"d":"html"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"articles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"content"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/devices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_devices_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"devices"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/donate_articles_banner(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_donate_articles_banner_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"donate_articles_banner"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/donate_horizontal_banner(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_donate_horizontal_banner_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"donate_horizontal_banner"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/donate_news_banner(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_donate_news_banner_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"donate_news_banner"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/donate_videos_banner(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_donate_videos_banner_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"donate_videos_banner"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/ebooks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_ebook_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"ebooks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/ebooks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_ebooks_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"ebooks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_episode_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes/:episode_slug/bookmarks/:id(.:format)
 * @param {any} episode_slug
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_episode_bookmark_path = __jsr.r({"episode_slug":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_slug"],[2,[7,"/"],[2,[6,"bookmarks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes/:episode_slug/bookmarks(.:format)
 * @param {any} episode_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_episode_bookmarks_path = __jsr.r({"episode_slug":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_slug"],[2,[7,"/"],[2,[6,"bookmarks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes/:episode_slug/comments/:id(.:format)
 * @param {any} episode_slug
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_episode_comment_path = __jsr.r({"episode_slug":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_slug"],[2,[7,"/"],[2,[6,"comments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes/:episode_slug/comments/:comment_id/reported_comments(.:format)
 * @param {any} episode_slug
 * @param {any} comment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_episode_comment_reported_comments_path = __jsr.r({"episode_slug":{"r":true},"comment_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_slug"],[2,[7,"/"],[2,[6,"comments"],[2,[7,"/"],[2,[3,"comment_id"],[2,[7,"/"],[2,[6,"reported_comments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes/:episode_slug/comments(.:format)
 * @param {any} episode_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_episode_comments_path = __jsr.r({"episode_slug":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_slug"],[2,[7,"/"],[2,[6,"comments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes/:episode_slug/comments_counter(.:format)
 * @param {any} episode_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_episode_comments_counter_path = __jsr.r({"episode_slug":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_slug"],[2,[7,"/"],[2,[6,"comments_counter"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes/:episode_id/documents(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_episode_documents_path = __jsr.r({"episode_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes/:episode_id/ebooks(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_episode_ebooks_path = __jsr.r({"episode_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"ebooks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes/:episode_id/guides(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_episode_guides_path = __jsr.r({"episode_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"guides"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes/:episode_id/images(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_episode_images_path = __jsr.r({"episode_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"images"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes/:episode_slug/like(.:format)
 * @param {any} episode_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_episode_like_path = __jsr.r({"episode_slug":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_slug"],[2,[7,"/"],[2,[6,"like"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes/:episode_id/links(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_episode_links_path = __jsr.r({"episode_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"links"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes/:episode_slug/notes(.:format)
 * @param {any} episode_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_episode_notes_path = __jsr.r({"episode_slug":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_slug"],[2,[7,"/"],[2,[6,"notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes/:episode_slug/passcodes(.:format)
 * @param {any} episode_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_episode_passcodes_path = __jsr.r({"episode_slug":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_slug"],[2,[7,"/"],[2,[6,"passcodes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes/:episode_id/previously_downloaded(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_episode_previously_downloaded_path = __jsr.r({"episode_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"previously_downloaded"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes/:episode_id/progress_bar(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_episode_progress_bar_index_path = __jsr.r({"episode_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"progress_bar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes/:episode_id/share_episode_url(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_episode_share_episode_url_path = __jsr.r({"episode_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"share_episode_url"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes/:episode_id/transcripts(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_episode_transcripts_path = __jsr.r({"episode_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"transcripts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes/:episode_id/video_resources(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_episode_video_resources_path = __jsr.r({"episode_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"video_resources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes/:episode_id/watch_later(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_episode_watch_later_path = __jsr.r({"episode_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"watch_later"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_episodes_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes/watched_episodes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_episodes_watched_episode_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[6,"watched_episodes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes/watched_episodes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_episodes_watched_episodes_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[6,"watched_episodes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/events/elements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_events_elements_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"events"],[2,[7,"/"],[2,[6,"elements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/expiration_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_expiration_password_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"expiration_password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/featured_articles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_featured_articles_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"featured_articles"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/articles/:id/featured_videos(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_featured_videos_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"articles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"featured_videos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/messages/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_message_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/messages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_messages_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"messages"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/news/:slug(.:format)
 * @param {any} slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_news_path = __jsr.r({"slug":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[3,"slug"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/news/banner_news(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_news_banner_news_index_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[6,"banner_news"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/news_bookmark_tags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_news_bookmark_tags_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"news_bookmark_tags"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/news_bookmarks/news(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_news_bookmarks_news_index_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"news_bookmarks"],[2,[7,"/"],[2,[6,"news"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/news/:news_slug/featured_videos(.:format)
 * @param {any} news_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_news_featured_videos_path = __jsr.r({"news_slug":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[3,"news_slug"],[2,[7,"/"],[2,[6,"featured_videos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/news(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_news_index_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"news"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/news/most_popular_news(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_news_most_popular_news_index_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[6,"most_popular_news"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/news/:news_slug/news_bookmarks/:slug(.:format)
 * @param {any} news_slug
 * @param {any} slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_news_news_bookmark_path = __jsr.r({"news_slug":{"r":true},"slug":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[3,"news_slug"],[2,[7,"/"],[2,[6,"news_bookmarks"],[2,[7,"/"],[2,[3,"slug"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/news/:news_slug/news_bookmarks(.:format)
 * @param {any} news_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_news_news_bookmarks_path = __jsr.r({"news_slug":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[3,"news_slug"],[2,[7,"/"],[2,[6,"news_bookmarks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/news/recent_searches(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_news_recent_searches_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[6,"recent_searches"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/news/:news_slug/share_news_url(.:format)
 * @param {any} news_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_news_share_news_url_path = __jsr.r({"news_slug":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[3,"news_slug"],[2,[7,"/"],[2,[6,"share_news_url"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/news/:news_slug/shared_news_bookmarks/:slug(.:format)
 * @param {any} news_slug
 * @param {any} slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_news_shared_news_bookmark_path = __jsr.r({"news_slug":{"r":true},"slug":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[3,"news_slug"],[2,[7,"/"],[2,[6,"shared_news_bookmarks"],[2,[7,"/"],[2,[3,"slug"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/news/:news_slug/suggested_news(.:format)
 * @param {any} news_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_news_suggested_news_index_path = __jsr.r({"news_slug":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[3,"news_slug"],[2,[7,"/"],[2,[6,"suggested_news"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/news/topic_sections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_news_topic_sections_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[6,"topic_sections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/news/topics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_news_topics_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[6,"topics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/news/you_may_also_like_news(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_news_you_may_also_like_news_index_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"news"],[2,[7,"/"],[2,[6,"you_may_also_like_news"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/notes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_note_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"notes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/note_tags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_note_tags_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"note_tags"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/notes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_notes_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"notes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/notes/episodes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_notes_episodes_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"notes"],[2,[7,"/"],[2,[6,"episodes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/notes/:note_id/formatting(.:format)
 * @param {any} note_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_notes_formatting_path = __jsr.r({"note_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"notes"],[2,[7,"/"],[2,[3,"note_id"],[2,[7,"/"],[2,[6,"formatting"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/online_streaming(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_online_streaming_index_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"online_streaming"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/online_streaming/schedule(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_online_streaming_schedule_index_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"online_streaming"],[2,[7,"/"],[2,[6,"schedule"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes/previously_downloaded(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_previously_downloaded_index_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[6,"previously_downloaded"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/profile/emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_profile_emails_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/profiles/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_profiles_password_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/profiles/profile/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_profiles_profile_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/ebooks/recent_searches(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_recent_searches_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"ebooks"],[2,[7,"/"],[2,[6,"recent_searches"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/recommended_articles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_recommended_articles_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"recommended_articles"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/report_reasons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_report_reasons_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"report_reasons"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/searches(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_searches_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"searches"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/series/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_series_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"series"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/series/:series_id/episodes(.:format)
 * @param {any} series_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_series_episodes_path = __jsr.r({"series_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"series"],[2,[7,"/"],[2,[3,"series_id"],[2,[7,"/"],[2,[6,"episodes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/series(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_series_index_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"series"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/series/:series_id/passcodes(.:format)
 * @param {any} series_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_series_passcodes_path = __jsr.r({"series_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"series"],[2,[7,"/"],[2,[3,"series_id"],[2,[7,"/"],[2,[6,"passcodes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/articles/:id/share_article_url(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_share_article_url_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"articles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"share_article_url"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/speakers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_speaker_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"speakers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/speakers/:speaker_id/series(.:format)
 * @param {any} speaker_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_speaker_series_index_path = __jsr.r({"speaker_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"speakers"],[2,[7,"/"],[2,[3,"speaker_id"],[2,[7,"/"],[2,[6,"series"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/speakers/:speaker_id/standalone_episodes(.:format)
 * @param {any} speaker_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_speaker_standalone_episodes_path = __jsr.r({"speaker_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"speakers"],[2,[7,"/"],[2,[3,"speaker_id"],[2,[7,"/"],[2,[6,"standalone_episodes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/speakers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_speakers_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"speakers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/tags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_tags_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"tags"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/topics/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_topic_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"topics"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/topics/:topic_id/cards(.:format)
 * @param {any} topic_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_topic_cards_path = __jsr.r({"topic_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"topics"],[2,[7,"/"],[2,[3,"topic_id"],[2,[7,"/"],[2,[6,"cards"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/topics/:topic_id/ebooks(.:format)
 * @param {any} topic_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_topic_ebooks_path = __jsr.r({"topic_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"topics"],[2,[7,"/"],[2,[3,"topic_id"],[2,[7,"/"],[2,[6,"ebooks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/topics/:topic_id/news(.:format)
 * @param {any} topic_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_topic_news_index_path = __jsr.r({"topic_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"topics"],[2,[7,"/"],[2,[3,"topic_id"],[2,[7,"/"],[2,[6,"news"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/topics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_topics_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"topics"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/unread_messages_counter(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_unread_messages_counter_index_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"unread_messages_counter"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_user_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"user"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/v1/bookmarks/episodes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_v1_bookmarks_episodes_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"bookmarks"],[2,[7,"/"],[2,[6,"episodes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/v1/watch/home_page/sections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_v1_watch_home_page_sections_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"home_page"],[2,[7,"/"],[2,[6,"sections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/video_resources_images/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_video_resources_image_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"video_resources_images"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/video_resources_images/:video_resources_image_id/share_image_url(.:format)
 * @param {any} video_resources_image_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_video_resources_image_share_image_url_path = __jsr.r({"video_resources_image_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"video_resources_images"],[2,[7,"/"],[2,[3,"video_resources_image_id"],[2,[7,"/"],[2,[6,"share_image_url"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/watch/episodes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_watch_episode_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/watch/home_page/sections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_watch_home_page_sections_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"home_page"],[2,[7,"/"],[2,[6,"sections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/watch/home_page/series_banner/slides(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_watch_home_page_series_banner_slides_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"home_page"],[2,[7,"/"],[2,[6,"series_banner"],[2,[7,"/"],[2,[6,"slides"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes/watch_later(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mobile_api_watch_later_index_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[6,"watch_later"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /studytools/my(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const my_profile_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"studytools"],[2,[7,"/"],[2,[6,"my"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/api/featured_videos/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_api_featured_video_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"featured_videos"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/articles/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_article_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"articles"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/article_categories/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_article_category_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"article_categories"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/article_subjects/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_article_subject_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"article_subjects"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/article_topics/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_article_topic_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"article_topics"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/authors/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_author_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"authors"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/banners/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_banner_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"banners"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/categories/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_category_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"categories"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ebook_topics/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_ebook_topic_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ebook_topics"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/email_notifications/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_email_notification_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"email_notifications"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/message_center/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_message_center_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"message_center"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/news_import/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_news_import_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"news_import"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/news_topics/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_news_topic_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"news_topics"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/passcodes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_passcode_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"passcodes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/series/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_series_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"series"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/series/:series_id/episodes/new(.:format)
 * @param {any} series_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_series_episode_path = __jsr.r({"series_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"series"],[2,[7,"/"],[2,[3,"series_id"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/speakers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_speaker_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"speakers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/standalone_episodes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_standalone_episode_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"standalone_episodes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/watch_topics/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_watch_topic_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"watch_topics"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/banners/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_banner_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"banners"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/documents/new(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_episode_document_path = __jsr.r({"episode_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/ebooks/new(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_episode_ebook_path = __jsr.r({"episode_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"ebooks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/guides/new(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_episode_guide_path = __jsr.r({"episode_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"guides"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/images/new(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_episode_image_path = __jsr.r({"episode_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"images"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/links/new(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_episode_link_path = __jsr.r({"episode_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"links"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/transcripts/new(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_episode_transcript_path = __jsr.r({"episode_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"transcripts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /auth/confirmation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_auth_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"confirmation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /auth/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_auth_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /auth/registration/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_auth_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"registration"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/auth/confirmation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_mobile_api_auth_confirmation_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"confirmation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/auth/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_mobile_api_auth_password_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/ebooks/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_mobile_api_ebook_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"ebooks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /mobile_api/episodes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_mobile_api_episode_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /prophecy-news/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const news_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"prophecy-news"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /studytools/:id/notes(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const notes_profile_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"studytools"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /online-streaming(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const online_streaming_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"online-streaming"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /our_apps(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const our_apps_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"our_apps"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /print_schedule(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const print_schedule_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"print_schedule"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /studytools/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const profile_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"studytools"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /profile/emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const profile_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /profile/emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const profile_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"emails"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/episodes/:episode_id/transcripts/public(.:format)
 * @param {any} episode_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const public_api_episode_transcripts_path = __jsr.r({"episode_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"episode_id"],[2,[7,"/"],[2,[6,"transcripts"],[2,[7,"/"],[2,[6,"public"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/articles/:id/publish(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publish_article_admin_article_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"articles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"publish"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/article_categories/:id/publish(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publish_article_category_admin_article_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"article_categories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"publish"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/article_subjects/:id/publish(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publish_article_subject_admin_article_subject_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"article_subjects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"publish"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /read/articles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const read_article_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"read"],[2,[7,"/"],[2,[6,"articles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /read/categories/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const read_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"read"],[2,[7,"/"],[2,[6,"categories"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /read/categories/:category_id/subjects/:id(.:format)
 * @param {any} category_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const read_category_subject_path = __jsr.r({"category_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"read"],[2,[7,"/"],[2,[6,"categories"],[2,[7,"/"],[2,[3,"category_id"],[2,[7,"/"],[2,[6,"subjects"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /read/topics/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const read_topic_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"read"],[2,[7,"/"],[2,[6,"topics"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /roku(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const roku_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"roku"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api-docs
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rswag_api_path = __jsr.r({}, [2,[7,"/"],[6,"api-docs"]]);

/**
 * Generates rails route to
 * /api-docs
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rswag_ui_path = __jsr.r({}, [2,[7,"/"],[6,"api-docs"]]);

/**
 * Generates rails route to
 * /schedule(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const schedule_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"schedule"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/message_center/:id/send(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_admin_message_center_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"message_center"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /auth/confirmation/sent(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sent_auth_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"confirmation"],[2,[7,"/"],[2,[6,"sent"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /auth/password/sent(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sent_auth_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"sent"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ebook/:id/show(/:name)(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_ebook_path = __jsr.r({"id":{"r":true},"name":{},"format":{}}, [2,[7,"/"],[2,[6,"ebook"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"show"],[2,[1,[2,[7,"/"],[3,"name"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * /sidekiq
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidekiq_web_path = __jsr.r({}, [2,[7,"/"],[6,"sidekiq"]]);

/**
 * Generates rails route to
 * /mobile_api/profiles/profile/states(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const states_mobile_api_profiles_profiles_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"mobile_api"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"states"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /studytools/states(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const states_profile_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"studytools"],[2,[7,"/"],[2,[6,"states"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /topics/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const topics_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"topics"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/videos/update_multiple(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_multiple_admin_videos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"videos"],[2,[7,"/"],[2,[6,"update_multiple"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/series/:series_id/episodes/:id/update_order(.:format)
 * @param {any} series_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_order_admin_series_episode_path = __jsr.r({"series_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"series"],[2,[7,"/"],[2,[3,"series_id"],[2,[7,"/"],[2,[6,"episodes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/speakers/:id/update_popularity(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_popularity_admin_speaker_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"speakers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_popularity"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /all-the-way-my-saviour-leads-me/21(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_all_the_way_my_saviour_leads_me_21_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"all-the-way-my-saviour-leads-me"],[2,[7,"/"],[2,[6,"21"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /am-i-a-man-or-a-beast/23(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_am_i_a_man_or_a_beast_23_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"am-i-a-man-or-a-beast"],[2,[7,"/"],[2,[6,"23"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /bible-answers/13(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_bible_answers_13_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"bible-answers"],[2,[7,"/"],[2,[6,"13"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /category/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"category"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /clash-of-minds/78(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_clash_of_minds_78_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"clash-of-minds"],[2,[7,"/"],[2,[6,"78"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /conflict-and-triumph/8(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_conflict_and_triumph_8_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"conflict-and-triumph"],[2,[7,"/"],[2,[6,"8"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /daniel-and-revelation-unlocked/90(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_daniel_and_revelation_unlocked_90_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"daniel-and-revelation-unlocked"],[2,[7,"/"],[2,[6,"90"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /darkness-before-dawn/39(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_darkness_before_dawn_39_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"darkness-before-dawn"],[2,[7,"/"],[2,[6,"39"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /did-darwin-murder-god/62(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_did_darwin_murder_god_62_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"did-darwin-murder-god"],[2,[7,"/"],[2,[6,"62"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /digging-up-the-past/123(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_digging_up_the_past_123_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"digging-up-the-past"],[2,[7,"/"],[2,[6,"123"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /divine-prescription/56(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_divine_prescription_56_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"divine-prescription"],[2,[7,"/"],[2,[6,"56"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /embedded/:series_id/:id(.:format)
 * @param {any} series_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_embedded_episode_path = __jsr.r({"series_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"embedded"],[2,[7,"/"],[2,[3,"series_id"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /embedded/single-episode/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_embedded_standalone_episode_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"embedded"],[2,[7,"/"],[2,[6,"single-episode"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /emnity/46(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_emnity_46_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"emnity"],[2,[7,"/"],[2,[6,"46"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /enter/33(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_enter_33_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"enter"],[2,[7,"/"],[2,[6,"33"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /:series_id/:id(.:format)
 * @param {any} series_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_episode_path = __jsr.r({"series_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"series_id"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /finding-the-fountain-of-youth/17(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_finding_the_fountain_of_youth_17_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"finding-the-fountain-of-youth"],[2,[7,"/"],[2,[6,"17"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /food-for-thought/80(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_food_for_thought_80_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"food-for-thought"],[2,[7,"/"],[2,[6,"80"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /from-crete-to-malta/15(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_from_crete_to_malta_15_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"from-crete-to-malta"],[2,[7,"/"],[2,[6,"15"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /from-sickness-to-health/45(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_from_sickness_to_health_45_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"from-sickness-to-health"],[2,[7,"/"],[2,[6,"45"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /genesis-conflict/14(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_genesis_conflict_14_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"genesis-conflict"],[2,[7,"/"],[2,[6,"14"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /have-you-swallowed-the-hook/64(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_have_you_swallowed_the_hook_64_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"have-you-swallowed-the-hook"],[2,[7,"/"],[2,[6,"64"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /studytools/:id/watch_history(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_history_profile_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"studytools"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"watch_history"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /hydrotherapy-and-natural-remedies/9(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_hydrotherapy_and_natural_remedies_9_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"hydrotherapy-and-natural-remedies"],[2,[7,"/"],[2,[6,"9"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /in-the-stream-of-time/32(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_in_the_stream_of_time_32_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"in-the-stream-of-time"],[2,[7,"/"],[2,[6,"32"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /introduction-to-soapmaking/3(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_introduction_to_soapmaking_3_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"introduction-to-soapmaking"],[2,[7,"/"],[2,[6,"3"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /lessons-from-the-life-of-john-the-baptist/7(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_lessons_from_the_life_of_john_the_baptist_7_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"lessons-from-the-life-of-john-the-baptist"],[2,[7,"/"],[2,[6,"7"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /life-at-its-best/12(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_life_at_its_best_12_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"life-at-its-best"],[2,[7,"/"],[2,[6,"12"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /life-science-seminars-international/49(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_life_science_seminars_international_49_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"life-science-seminars-international"],[2,[7,"/"],[2,[6,"49"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /lightning-from-heaven/82(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_lightning_from_heaven_82_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"lightning-from-heaven"],[2,[7,"/"],[2,[6,"82"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /mind-of-god/35(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_mind_of_god_35_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"mind-of-god"],[2,[7,"/"],[2,[6,"35"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /old-mountain-remedies/42(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_old_mountain_remedies_42_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"old-mountain-remedies"],[2,[7,"/"],[2,[6,"42"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /preaching-from-the-grave/6(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_preaching_from_the_grave_6_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"preaching-from-the-grave"],[2,[7,"/"],[2,[6,"6"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /proven-principles-for-good-health/31(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_proven_principles_for_good_health_31_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"proven-principles-for-good-health"],[2,[7,"/"],[2,[6,"31"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rekindling-the-reformation/16(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_rekindling_the_reformation_16_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rekindling-the-reformation"],[2,[7,"/"],[2,[6,"16"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /repairing-the-breach/98(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_repairing_the_breach_98_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"repairing-the-breach"],[2,[7,"/"],[2,[6,"98"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /series/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_series_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"series"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /single-episode/good-and-evil(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_single_episode_good_and_evil_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"single-episode"],[2,[7,"/"],[2,[6,"good-and-evil"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /single-sermons/11(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_single_sermons_11_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"single-sermons"],[2,[7,"/"],[2,[6,"11"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sitemap(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_sitemap_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sitemap"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /speaker/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_speaker_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"speaker"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /single-episode/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_standalone_episode_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"single-episode"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /the-herb-cabinet/1(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_the_herb_cabinet_1_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"the-herb-cabinet"],[2,[7,"/"],[2,[6,"1"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /the-sanctuary-surrender-and-spirit-of-the-last-days/83(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_the_sanctuary_surrender_and_spirit_of_the_last_days_83_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"the-sanctuary-surrender-and-spirit-of-the-last-days"],[2,[7,"/"],[2,[6,"83"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /the-struggle-is-real/27(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_the_struggle_is_real_27_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"the-struggle-is-real"],[2,[7,"/"],[2,[6,"27"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /topic/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_topic_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"topic"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /total-onslaught/18(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_total_onslaught_18_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"total-onslaught"],[2,[7,"/"],[2,[6,"18"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /total-transformation/70(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_total_transformation_70_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"total-transformation"],[2,[7,"/"],[2,[6,"70"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /twisted-perspectives/10(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_twisted_perspectives_10_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"twisted-perspectives"],[2,[7,"/"],[2,[6,"10"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /understanding-the-latter-rain/5(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_understanding_the_latter_rain_5_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"understanding-the-latter-rain"],[2,[7,"/"],[2,[6,"5"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /video-sitemap(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_video_sitemap_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"video-sitemap"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /watch/ancient-prophecy-correctly-predicts-the-future-daniel-2-unlocks-the-mists-of-time/120(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_ancient_prophecy_correctly_predicts_the_future_daniel_2_unlocks_the_mists_of_time_120_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"ancient-prophecy-correctly-predicts-the-future-daniel-2-unlocks-the-mists-of-time"],[2,[7,"/"],[2,[6,"120"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/antichrist-revealed-the-true-identity-they-don-t-want-you-to-know-the-man-behind-the-mask/146(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_antichrist_revealed_the_true_identity_they_don_t_want_you_to_know_the_man_behind_the_mask_146_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"antichrist-revealed-the-true-identity-they-don-t-want-you-to-know-the-man-behind-the-mask"],[2,[7,"/"],[2,[6,"146"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/are-the-teachings-of-the-catholic-church-biblical-or-pagan-the-wine-of-babylon/222(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_are_the_teachings_of_the_catholic_church_biblical_or_pagan_the_wine_of_babylon_222_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"are-the-teachings-of-the-catholic-church-biblical-or-pagan-the-wine-of-babylon"],[2,[7,"/"],[2,[6,"222"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/beast-from-the-bottomless-pit-exposing-satan-s-kingdom/124(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_beast_from_the_bottomless_pit_exposing_satan_s_kingdom_124_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"beast-from-the-bottomless-pit-exposing-satan-s-kingdom"],[2,[7,"/"],[2,[6,"124"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/did-catholicism-create-islam-the-true-origins-of-the-islam-revealed-the-islamic-connection/130(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_did_catholicism_create_islam_the_true_origins_of_the_islam_revealed_the_islamic_connection_130_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"did-catholicism-create-islam-the-true-origins-of-the-islam-revealed-the-islamic-connection"],[2,[7,"/"],[2,[6,"130"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/do-bible-versions-change-god-s-word-changing-the-word/127(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_do_bible_versions_change_god_s_word_changing_the_word_127_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"do-bible-versions-change-god-s-word-changing-the-word"],[2,[7,"/"],[2,[6,"127"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/do-freemasons-worship-lucifer-evidence-they-don-t-want-you-to-see-hidden-agendas/220(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_do_freemasons_worship_lucifer_evidence_they_don_t_want_you_to_see_hidden_agendas_220_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"do-freemasons-worship-lucifer-evidence-they-don-t-want-you-to-see-hidden-agendas"],[2,[7,"/"],[2,[6,"220"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/essential-oils-part-1/20(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_essential_oils_part_1_20_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"essential-oils-part-1"],[2,[7,"/"],[2,[6,"20"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/freemasons-worship-lucifer-hidden-agendas/220(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_freemasons_worship_lucifer_hidden_agendas_220_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"freemasons-worship-lucifer-hidden-agendas"],[2,[7,"/"],[2,[6,"220"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/hidden-secrets-of-the-sanctuary-revealed-jesus-and-the-plan-of-salvation-an-advocate-for-our-time/143(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_hidden_secrets_of_the_sanctuary_revealed_jesus_and_the_plan_of_salvation_an_advocate_for_our_time_143_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"hidden-secrets-of-the-sanctuary-revealed-jesus-and-the-plan-of-salvation-an-advocate-for-our-time"],[2,[7,"/"],[2,[6,"143"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/introduction-to-soapmaking/44(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_introduction_to_soapmaking_44_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"introduction-to-soapmaking"],[2,[7,"/"],[2,[6,"44"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/is-revelation-meant-to-be-understood-the-revelation-of-jesus-christ/121(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_is_revelation_meant_to_be_understood_the_revelation_of_jesus_christ_121_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"is-revelation-meant-to-be-understood-the-revelation-of-jesus-christ"],[2,[7,"/"],[2,[6,"121"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/is-revelation-meant-to-be-understood-unlocking-rev-1-the-revelation-of-jesus-christ/121(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_is_revelation_meant_to_be_understood_unlocking_rev_1_the_revelation_of_jesus_christ_121_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"is-revelation-meant-to-be-understood-unlocking-rev-1-the-revelation-of-jesus-christ"],[2,[7,"/"],[2,[6,"121"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/jesus-christ-just-another-man-or-son-of-god/118(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_jesus_christ_just_another_man_or_son_of_god_118_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"jesus-christ-just-another-man-or-son-of-god"],[2,[7,"/"],[2,[6,"118"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/kitchen-medicine/240(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_kitchen_medicine_240_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"kitchen-medicine"],[2,[7,"/"],[2,[6,"240"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/light-upon-the-path/9(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_light_upon_the_path_9_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"light-upon-the-path"],[2,[7,"/"],[2,[6,"9"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/our-personal-god/63(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_our_personal_god_63_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"our-personal-god"],[2,[7,"/"],[2,[6,"63"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/pearls-of-truth-in-settings-of-gold/7(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_pearls_of_truth_in_settings_of_gold_7_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"pearls-of-truth-in-settings-of-gold"],[2,[7,"/"],[2,[6,"7"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/poultices-and-fomentations/17(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_poultices_and_fomentations_17_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"poultices-and-fomentations"],[2,[7,"/"],[2,[6,"17"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/preparing-the-brain-for-battle/37(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_preparing_the_brain_for_battle_37_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"preparing-the-brain-for-battle"],[2,[7,"/"],[2,[6,"37"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/the-character-of-god-on-trial/8(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_the_character_of_god_on_trial_8_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"the-character-of-god-on-trial"],[2,[7,"/"],[2,[6,"8"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/the-cornerstone-of-protestantism/233(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_the_cornerstone_of_protestantism_233_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"the-cornerstone-of-protestantism"],[2,[7,"/"],[2,[6,"233"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/the-mind-of-the-master-part-2/32(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_the_mind_of_the_master_part_2_32_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"the-mind-of-the-master-part-2"],[2,[7,"/"],[2,[6,"32"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/the-real-life-history-of-messiah-where-jesus-walked/119(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_the_real_life_history_of_messiah_where_jesus_walked_119_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"the-real-life-history-of-messiah-where-jesus-walked"],[2,[7,"/"],[2,[6,"119"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/the-secret-behind-secret-societies/125(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_the_secret_behind_secret_societies_125_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"the-secret-behind-secret-societies"],[2,[7,"/"],[2,[6,"125"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/the-true-cause-of-disease-part-1/248(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_the_true_cause_of_disease_part_1_248_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"the-true-cause-of-disease-part-1"],[2,[7,"/"],[2,[6,"248"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/tribulation-song/30(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_tribulation_song_30_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"tribulation-song"],[2,[7,"/"],[2,[6,"30"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/what-are-the-messages-for-the-seven-churches-of-asia-seven-churches-explained/122(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_what_are_the_messages_for_the_seven_churches_of_asia_seven_churches_explained_122_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"what-are-the-messages-for-the-seven-churches-of-asia-seven-churches-explained"],[2,[7,"/"],[2,[6,"122"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/what-are-the-real-goals-of-the-new-world-order-the-plan-as-you-ve-never-seen-it-a-new-world-order/149(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_what_are_the_real_goals_of_the_new_world_order_the_plan_as_you_ve_never_seen_it_a_new_world_order_149_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"what-are-the-real-goals-of-the-new-world-order-the-plan-as-you-ve-never-seen-it-a-new-world-order"],[2,[7,"/"],[2,[6,"149"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/who-are-the-real-forces-behind-nwo-centuries-of-organized-deception-revolutions-tyrants-wars/128(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_who_are_the_real_forces_behind_nwo_centuries_of_organized_deception_revolutions_tyrants_wars_128_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"who-are-the-real-forces-behind-nwo-centuries-of-organized-deception-revolutions-tyrants-wars"],[2,[7,"/"],[2,[6,"128"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /watch/why-so-many-bible-versions-the-untold-dark-history-of-bible-translations-battle-of-the-bibles/126(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_watch_why_so_many_bible_versions_the_untold_dark_history_of_bible_translations_battle_of_the_bibles_126_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"watch"],[2,[7,"/"],[2,[6,"why-so-many-bible-versions-the-untold-dark-history-of-bible-translations-battle-of-the-bibles"],[2,[7,"/"],[2,[6,"126"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /what-are-we-doing-with-the-health-message/2(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_what_are_we_doing_with_the_health_message_2_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"what-are-we-doing-with-the-health-message"],[2,[7,"/"],[2,[6,"2"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /zechariah/4(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const watch_zechariah_4_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"zechariah"],[2,[7,"/"],[2,[6,"4"],[1,[2,[8,"."],[3,"format"]]]]]]]);


